import { LinkComponentProvider } from "@apollosproject/canvas-ui-web";
import { Link as RemixLink } from "@remix-run/react";
import { forwardRef } from "react";

const LinkComponent = forwardRef(function LinkComponent(
  { to, href, isExternal, ...props },
  ref
) {
  if ((isExternal && href) || !to) {
    return <a ref={ref} href={href} {...props} />;
  }
  return <RemixLink ref={ref} to={to} {...props} />;
});

export const LinkingProvider = ({ children }) => {
  return (
    <LinkComponentProvider linkComponent={LinkComponent}>
      {children}
    </LinkComponentProvider>
  );
};
